/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useMemo } from "react";
import {
  ExtendedFee,
  ReviewSummaryRenderProps,
} from "@narvar/nth-kit-returns-headless";
import {
  createFee,
  transformDiscounts,
  transformFees,
  transformOrderItems,
  transformRefund,
  transformSelectedItems,
  transformShopNowItems,
} from "../util/transformToNth";
import {
  Cart,
  ReturnCart,
  ShopNowV2CheckoutQuery,
} from "../../../gql/__generated__/schema";

export const prepareReturnCartCostFees = (returnCartSummary: ReturnCart) => {
  const returnSubtotal = createFee(
    "Return Subtotal",
    returnCartSummary.cartSummary.subtotal,
  );
  const returnTax = createFee("Return Tax", returnCartSummary.cartSummary.tax);
  const returnFees = transformFees(returnCartSummary.feeBreakdown);

  return [returnSubtotal, returnTax, ...returnFees];
};

const prepareFees = (shopNowCartSummary: Cart): ExtendedFee[] => {
  const cartSubtotal = createFee(
    "Cart Items",
    shopNowCartSummary.cartSummary.subtotal,
  );
  const cartTax = createFee("Cart Tax", shopNowCartSummary.cartSummary.tax);
  const cartFees = transformFees(shopNowCartSummary.feeBreakdown);

  const discounts = transformDiscounts(
    shopNowCartSummary.cartSummary.discounts ?? [],
  );
  const cartTotal = createFee(
    "Cart Total",
    shopNowCartSummary.cartSummary.total,
  );

  return [cartSubtotal, cartTax, ...discounts, ...cartFees, cartTotal];
};

type TransformToNthProps = Pick<
  ReviewSummaryRenderProps,
  | "refund"
  | "fees"
  | "orderItems"
  | "selectedItems"
  | "shopNowItems"
  | "returnCart"
>;

const useTransformToNth = (
  checkoutData: ShopNowV2CheckoutQuery["shopNowV2Checkout"]["data"] | null,
): TransformToNthProps =>
  useMemo(() => {
    if (!checkoutData)
      return {
        fees: [],
        refund: undefined,
        orderItems: [],
        selectedItems: [],
        shopNowItems: [],
        returnCart: undefined,
      };
    const { refund, overallCartSummary, shopNowCart, returnCart } =
      checkoutData;

    const nthFees = overallCartSummary ? prepareFees(shopNowCart) : [];

    const nthRefund =
      refund && overallCartSummary
        ? transformRefund(refund, overallCartSummary, shopNowCart.cartSummary)
        : undefined;

    return {
      fees: nthFees,
      refund: nthRefund,
      orderItems: transformOrderItems(returnCart.lineItems),
      selectedItems: transformSelectedItems(returnCart.lineItems),
      shopNowItems: transformShopNowItems(shopNowCart.lineItems),

      //TODO: Remove hardcoded values post integration with API to enable submit button
      returnCart: {
        createdDate: "",
        expirationDate: "",
        lastUpdatedDate: "",
        orderId: "1",
        id: "1",
        retailerMoniker: "a",
        pudoOption: {
          pudoActionType: "DROP_OFF",
          pudoActionTypeDetails: "BOXLESS",
        },
      },
    };
  }, [checkoutData]);

export default useTransformToNth;
