import { useState, useCallback } from "react";
import { useConsumerPreferences } from "../../data/consumerPreferences";
import useCheckoutLocalStorageData from "../../hooks/useCheckoutLocalStorageData";

export const usePreferences = () => {
  const { confirmationData } = useCheckoutLocalStorageData();
  const {
    preferencesStatus,
    updateConsumerPreferences: updateConsumerPreferencesApi,
  } = useConsumerPreferences(
    confirmationData?.guestReturn ? "" : confirmationData?.email,
  );

  const [savePreferences, setSavePreferences] = useState(
    confirmationData?.savePreferences,
  );

  const updateConsumerPreferences = useCallback(() => {
    if (savePreferences && confirmationData?.type) {
      return updateConsumerPreferencesApi(
        confirmationData.orderId,
        confirmationData.email,
        {
          returnMethods: [confirmationData.type],
          carriers: [],
          locations: [],
        },
      ).catch(() => {
        console.error("Error updating consumer preferences");
      });
    }
    return Promise.resolve();
  }, [confirmationData, savePreferences, updateConsumerPreferencesApi]);

  return {
    preferencesStatus,
    savePreferences,
    setSavePreferences,
    updateConsumerPreferences,
  };
};
