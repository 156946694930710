import React, { FC, useMemo, useState } from "react";
import { clsx } from "clsx";
import Panel from "@narvar/nth-block-panel";
import PanelHeader from "@narvar/nth-bit-panel-header";
import Icon from "@narvar/nth-bit-icon-button";
import { useStyles } from "./styles";
import { ReturnCart } from "app/javascript/gql/__generated__/schema";
import { prepareReturnCartCostFees } from "./hooks/useTransformToNth";
import useConsumerSettings from "../hooks/useConsumerSettings";
import { Currency, ExtendedFee } from "@narvar/nth-kit-returns-headless";

interface CostItemProps {
  label: string;
  fee: ExtendedFee;
  isStrong?: boolean;
  isGreen?: boolean;
}

export const CostItem: FC<CostItemProps> = ({
  label,
  fee,
  isStrong = false,
  isGreen = false,
}) => {
  const { localeFull, includeCurrency } = useConsumerSettings();
  const styles: any = useStyles();

  const classes = clsx({
    [styles.cartSummaryItemWrapper]: true,
    [styles.textBold]: isStrong,
    [styles.textGreen]: isGreen,
  });
  return (
    <div className={classes}>
      <span>{label}</span>
      <span>
        <Currency
          locale={localeFull}
          includeCurrency={includeCurrency}
          currency={fee.amount.currency}
          value={fee.amount.value}
        />
      </span>
    </div>
  );
};

interface ReturnCostPanelHeaderProps {
  returnCartSummary?: ReturnCart;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const ReturnCostPanelHeader: FC<ReturnCostPanelHeaderProps> = ({
  returnCartSummary,
  open,
  setOpen,
}) => {
  const { localeFull, includeCurrency } = useConsumerSettings();
  return (
    <PanelHeader>
      <table style={{ width: "100%", padding: "0 1rem" }}>
        <tbody>
          <tr>
            <td />
            <td style={{ width: "100%" }}>Return Credits</td>
            <td style={{ textAlign: "right" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Currency
                  locale={localeFull}
                  includeCurrency={includeCurrency}
                  currency={returnCartSummary?.cartSummary.total.currencyCode}
                  value={returnCartSummary?.cartSummary.total.amount ?? 0}
                />
                <Icon hover onClick={() => setOpen(!open)}>
                  <span style={{ color: "white", fontSize: "2rem" }}>
                    {open ? <span>&#9650;</span> : <span>&#9660;</span>}
                  </span>
                </Icon>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </PanelHeader>
  );
};

interface ReturnCartCostPanelProps {
  returnCartSummary?: ReturnCart;
}

const ReturnCartCostPanel: FC<ReturnCartCostPanelProps> = ({
  returnCartSummary,
}) => {
  const [open, setOpen] = useState(false);
  const fees = useMemo(() => {
    if (!returnCartSummary) {
      return null;
    }
    return prepareReturnCartCostFees(returnCartSummary);
  }, [returnCartSummary]);

  return (
    <Panel
      header={
        <ReturnCostPanelHeader
          returnCartSummary={returnCartSummary}
          open={open}
          setOpen={setOpen}
        />
      }
      collapsible
      open={open}>
      <ul>
        {fees?.map((fee, index) => (
          <li key={index}>
            <CostItem key={index} label={fee.title ?? ""} fee={fee} />
          </li>
        ))}
      </ul>
    </Panel>
  );
};

export default ReturnCartCostPanel;
