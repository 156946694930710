import { Theme } from "@material-ui/core";
import { makeRootStyles } from "../theme/styles";

const useStyles = makeRootStyles(
  (theme: Theme) => ({
    root: {
      marginTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    loadingContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: 12,
    },
    discountContainer: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
    },
    discountInputField: {
      flex: 1,
      maxWidth: "300px",
    },
    discountChip: {
      backgroundColor: "#f3f3f3",
      padding: "5px 10px",
      borderRadius: "2px",
      fontSize: "14px",
      maxWidth: "300px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    discountApplyButton: {
      textTransform: "none",
    },
    cartSummaryItemWrapper: {
      display: "flex",
      justifyContent: "space-between",
    },
    textBold: {
      fontWeight: "bold",
    },
    textGreen: {
      color: theme.palette.success.dark,
    },
  }),
  { name: "N0Confirm" },
);

export { useStyles };
