import { useState } from "react";
import { useStripeComponents } from "../../contexts/StripeProvider";
import { confirmCardPayment } from "../../steps/Review/CheckoutForm";
import { Address } from "./useAddress";

interface PaymentProps {
  secret: string;
  newAddressDraft: Address;
}

interface CardPaymentResult {
  paymentId: string;
}

export type SubmitPaymentCheckoutFn = () => Promise<CardPaymentResult>;

const usePayment = ({ secret }: PaymentProps) => {
  const [capturingPayment, setCapturingPayment] = useState(false);
  const { stripe, elements: stripeElement } = useStripeComponents();
  const [paymentError, setPaymentError] = useState<string>();
  const [succeededPaymentIntentId, setSucceededPaymentIntentId] = useState("");

  const makePayment: SubmitPaymentCheckoutFn = () => {
    setCapturingPayment(true);
    setPaymentError("");
    setSucceededPaymentIntentId("");

    return confirmCardPayment({
      stripe,
      elements: stripeElement,
      secret,
    })
      .then((result: CardPaymentResult) => {
        setCapturingPayment(false);
        setSucceededPaymentIntentId(result.paymentId);
        return result;
      })
      .catch((error: Error) => {
        setCapturingPayment(false);
        setPaymentError(error.message ?? error);
        throw error;
      });
  };

  return {
    capturingPayment,
    succeededPaymentIntentId,
    makePayment,
    paymentError,
  };
};

export default usePayment;
