import { useMemo, useState } from "react";
import { ADDRESS_DEFAULT, mergeAddress } from "../../modules/address";

export interface Address {
  firstName: string;
  lastName: string;
  address1: string;
  address2: string;
  city: string;
  zip: string;
  province: string;
  provinceCode: string;
  country: string;
  countryCode: string;
  countryCodeV2: string;
}

export interface AddressEvent {
  fromAddress: Address;
  isValid: boolean;
}

const useAddress = () => {
  const [isNewAddressValid, setIsNewAddressValid] = useState(false);
  const [newAddressDraft, setNewAddressDraft] = useState(ADDRESS_DEFAULT);

  const address = useMemo(
    () =>
      mergeAddress(
        // TODO: Add the missing address which ran into error
        // state.fromAddress,
        // submittingAddressError.address,
        newAddressDraft,
      ),
    [newAddressDraft],
  );

  const onChangeAddress = (event: AddressEvent) => {
    const { fromAddress, isValid } = event;
    setIsNewAddressValid(isValid);
    setNewAddressDraft(fromAddress);
  };

  return {
    onChangeAddress,
    isNewAddressValid,
    newAddressDraft,
    address,
  };
};

export default useAddress;
