import { Button, TextField, Chip } from "@material-ui/core";
import React, { FC, useState } from "react";
import { useStyles } from "./styles";
import { config } from "../config";
import Spinner from "../../shared/components/Spinner";

interface DiscountCodeProps {
  discountCode?: string | null;
  onApply: (code: string) => Promise<void>;
  onRemove: () => Promise<void>;
  discountLoading?: boolean;
}

const DiscountCode: FC<DiscountCodeProps> = ({
  discountCode,
  onApply,
  onRemove,
  discountLoading,
}) => {
  const [inputValue, setInputValue] = useState("");
  const classes: any = useStyles();

  const onClickApply = () => {
    onApply(inputValue)
      .then(() => setInputValue(""))
      .catch(console.error);
  };

  const onClickRemove = () => {
    onRemove().catch(console.error);
  };

  return (
    <div className={classes.discountContainer}>
      {discountCode ? (
        <Chip
          label={discountCode}
          onDelete={onClickRemove}
          disabled={discountLoading}
          className={classes.discountChip}
        />
      ) : (
        <>
          <TextField
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
            //TODO: Add translations
            placeholder="Discount code"
            variant="outlined"
            size="small"
            className={classes.discountInputField}
          />
          <Button
            id="choose-items-next"
            className={classes.discountApplyButton}
            color="primary"
            onClick={onClickApply}
            disabled={!inputValue.trim() || discountLoading}
            variant="contained">
            <strong>
              {/* TODO: Add translations */}
              {config.translations.checkout_discount_apply ?? "Apply"}
            </strong>
          </Button>
        </>
      )}
      {discountLoading && <Spinner disablePadding />}
    </div>
  );
};

export default DiscountCode;
