import { isEmpty } from "lodash";
import { useMemo } from "react";
import { config } from "../../config";

const useNthRefund = (refund, isCharge, refundMethod) =>
  useMemo(() => {
    if (!isEmpty(refund)) {
      const currency = refund.currency;
      const total = refund.totalRefundCents;
      const subtotal =
        config.shouldHideNarvarSeamlessExchangeValue && total == 0
          ? 0
          : refund.subtotalRefundCents;
      const ret = {
        displayAllFees: true,
        total: { value: total, currency },
        subtotal: { value: subtotal, currency },
        refundMethod: refundMethod,
      };
      return ret;
    }
  }, [refund, isCharge, refundMethod]);

export default useNthRefund;
