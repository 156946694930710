import React, { useMemo, useEffect, useState } from "react";
import { v4 } from "uuid";
import TypeForm from "../components/type-form";
import TypeFormSection from "../components/type-form/TypeFormSection";
import {
  useReturnStepsState,
  getStepIndex,
  ORDER_LOOKUP_STEP,
  useOrderFetchData,
} from "../contexts/returnSteps";
import { useShopNowExpiration } from "../data/shopNowExpiration";
import { useCancelShopNow } from "../data/cancelShopNow";

import OrderLookup from "./OrderLookup/OrderLookup";
import ChooseItems from "./ChooseItems/ChooseItems";
import ChooseMethod from "./ChooseMethod/ChooseMethod";
import ChooseMethodSubstep from "./ChooseMethodSubstep/ChooseMethodSubstep";
import ConfirmMailingAddress from "./ConfirmMailingAddress/ConfirmMailingAddress";
import ChooseShopNow from "./ChooseShopNow/ChooseShopNow";
import ChooseReturnCreditMethod from "./ChooseReturnCreditMethod/ChooseReturnCreditMethod";
import Review from "./Review/Review";
import Confirmation from "./Confirmation/Confirmation";
import TypeFormProvider from "../contexts/typeForm";

function Stepper() {
  const { state, actions } = useOrderFetchData();
  const { state: shopNowState, checkShopNow } = useShopNowExpiration();
  const { state: cancelShopNowState, cancelShopNow } = useCancelShopNow();
  const [draftReturnUuid, setDraftReturnUuid] = useState();
  const stepsState = useReturnStepsState();

  useEffect(() => {
    if (draftReturnUuid) return;
    setDraftReturnUuid(() => v4());
  }, []);

  return (
    <TypeFormProvider>
      <TypeForm activeIndex={stepsState.currentStepIndex}>
        {!stepsState.submitted && (
          <OrderLookup
            onOrderFetch={actions.getOrderData}
            orderFetchData={state.data}
            orderFetchError={state.error}
            orderFetchLoading={state.loading}
            checkShopNow={checkShopNow}
            shopNowData={shopNowState.data}
            shopNowError={shopNowState.error}
            shopNowLoading={shopNowState.loading}
            cancelShopNow={cancelShopNow}
            shopNowCancelData={cancelShopNowState.data}
            shopNowCancelError={cancelShopNowState.error}
            shopNowCancelLoading={cancelShopNowState.loading}
          />
        )}
        {!stepsState.submitted && <ChooseItems orderFetchData={state.data} />}
        {!stepsState.submitted && <ChooseMethod orderFetchData={state.data} draftReturnUuid={draftReturnUuid} />}
        {!stepsState.submitted && (
          <ChooseMethodSubstep orderFetchData={state.data} />
        )}
        {!stepsState.submitted && (
          <ConfirmMailingAddress orderFetchData={state.data} />
        )}
        {!stepsState.submitted && (
          <ChooseShopNow
            orderFetchData={state.data}
            draftReturnUuid={draftReturnUuid}
          />
        )}
        {!stepsState.submitted && (
          <ChooseReturnCreditMethod
            orderFetchData={state.data}
            draftReturnUuid={draftReturnUuid}
          />
        )}
        <Review orderFetchData={state.data} draftReturnUuid={draftReturnUuid} />
        <Confirmation orderFetchData={state.data} />
      </TypeForm>
    </TypeFormProvider>
  );
}
export default Stepper;
