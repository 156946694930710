import React, { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import isNil from "lodash/isNil";
import { Grid, Typography } from "@material-ui/core";
import {
  CommonEvent,
  ReviewSummaryPayload,
  ReviewSummaryRaw,
} from "@narvar/nth-kit-returns-headless";
import ReturnCartCostPanel from "./ReturnCartCostPanel";
import { useStyles } from "./styles";
import useTransformToNth from "./hooks/useTransformToNth";
import { useShopNowV2CheckoutQuery } from "../../gql/__generated__/schema";
import useConsumerSettings from "../hooks/useConsumerSettings";
import CheckoutForm from "../steps/Review/CheckoutForm";
import { ADDRESS_DEFAULT, mergeAddress } from "../modules/address";
import useAddress from "./hooks/useAddress";
import BillingAddress from "./BillingAddress";
import usePayment from "./hooks/usePayment";
import useSubmit from "./hooks/useSubmit";
import { useDiscountCode } from "./hooks/useDiscountCode";
import { redirectToShop } from "../../shared/modules/frame";
import DiscountCode from "./DiscountCode";
import { config } from "../config";
import { usePreferences } from "./hooks/usePreferences";

const Checkout = () => {
  const classes: any = useStyles();

  const [searchParams] = useSearchParams();
  const code = searchParams.get("c") ?? "";
  const token = searchParams.get("t") ?? "";
  const { localeFull, includeCurrency } = useConsumerSettings();
  const { onChangeAddress, newAddressDraft } = useAddress();

  const {
    data: checkoutResponse,
    loading: loadingCheckoutData,
    refetch: refetchCheckoutData,
  } = useShopNowV2CheckoutQuery({
    variables: {
      cartToken: token,
      code: code,
    },
  });

  const checkoutData = checkoutResponse?.shopNowV2Checkout.data;
  const paymentClientSecret = checkoutData?.payment?.paymentClientSecret ?? "";
  const paymentRequired =
    (checkoutData?.overallCartSummary?.total?.amount || 0) > 0;
  const discountCodeSaved = checkoutData?.overallCartSummary.discountCodes?.[0];

  const onSuccessDiscountCodeApply = useCallback(async () => {
    await refetchCheckoutData();
  }, [refetchCheckoutData]);

  const {
    discountCode,
    applyDiscount,
    removeDiscount,
    discountError,
    discountLoading,
  } = useDiscountCode({
    code,
    token,
    discountCodeSaved,
    onSuccess: onSuccessDiscountCodeApply,
  });

  const {
    preferencesStatus,
    savePreferences,
    setSavePreferences,
    updateConsumerPreferences,
  } = usePreferences();

  const { paymentError, makePayment, capturingPayment } = usePayment({
    secret: paymentClientSecret,
    newAddressDraft,
  });

  const reviewSummaryProps = useTransformToNth(checkoutData);

  const { loadingSubmitCheckout, onSubmitHandler, submitData } = useSubmit({
    paymentClientSecret,
    paymentRequired,
    makePayment,
    code,
    updateConsumerPreferences,
    cartToken: token,
  });

  const checkoutErrorCode = checkoutResponse?.shopNowV2Checkout.errors[0]?.code;

  useEffect(() => {
    if (checkoutErrorCode === "Z501") {
      redirectToShop();
    }
  }, [checkoutErrorCode]);

  const errorMessage =
    submitData?.shopNowV2Checkout.errors[0]?.message ??
    checkoutResponse?.shopNowV2Checkout.errors[0]?.message ??
    discountError;

  const handleReviewSummaryChange: CommonEvent<
    ReviewSummaryPayload,
    string
  > = event => {
    if (!isNil(event.value?.savePreferences)) {
      setSavePreferences(event.value.savePreferences);
    }
  };

  return (
    <Grid className={classes.root} spacing={2} container>
      <Grid xs={12} item>
        <ReviewSummaryRaw
          locale={localeFull}
          includeCurrency={includeCurrency}
          error={errorMessage}
          loading={loadingCheckoutData}
          submitting={capturingPayment || loadingSubmitCheckout}
          disabled={discountLoading || capturingPayment || loadingCheckoutData}
          allowRetry
          hasAssociatedEmail
          savePreferences={config.isUserPreferencesEnabled && savePreferences}
          preferencesStatus={preferencesStatus}
          {...reviewSummaryProps}
          onChange={handleReviewSummaryChange}
          addOnsPostCartSummary={
            <ReturnCartCostPanel returnCartSummary={checkoutData?.returnCart} />
          }
          renderAddOn={
            <>
              <DiscountCode
                discountCode={discountCode}
                onApply={applyDiscount}
                onRemove={removeDiscount}
                discountLoading={discountLoading}
              />
              {paymentRequired && (
                <CheckoutForm
                  secret={paymentClientSecret}
                  onSuccess={null}
                  hideSubmit
                  submitLoading={capturingPayment}
                  backendPaymentFailureError={paymentError as any}
                />
              )}
              <BillingAddress
                address={mergeAddress(ADDRESS_DEFAULT)}
                onChange={onChangeAddress}
              />
            </>
          }
          onBack={redirectToShop}
          //eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={onSubmitHandler}
        />
        {errorMessage ? (
          <Typography variant="h1" color="error">
            {errorMessage}
          </Typography>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default Checkout;
