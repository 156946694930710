import { useEffect, useState } from "react";
import { useApplyDiscountCodeMutation } from "../../../gql/__generated__/schema";

interface DiscountCodeProps {
  code: string;
  token: string;
  discountCodeSaved?: string | null;
  onSuccess: () => Promise<void>;
}

export const useDiscountCode = ({
  code,
  token,
  discountCodeSaved,
  onSuccess,
}: DiscountCodeProps) => {
  const [discountCode, setDiscountCode] = useState(discountCodeSaved);
  const [discountLoading, setDiscountLoading] = useState(false);

  useEffect(() => {
    setDiscountCode(discountCodeSaved);
  }, [discountCodeSaved]);

  const [
    submitDiscountApi,
    { data: discountSubmitData, error: discountApiError },
  ] = useApplyDiscountCodeMutation();

  const applyDiscount = (newDiscountCode: string) => {
    setDiscountCode(newDiscountCode);
    setDiscountLoading(true);

    if (!newDiscountCode)
      return Promise.reject(new Error("No discount code provided"));
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    return submitDiscountApi({
      variables: {
        cartToken: token,
        code,
        couponCodes: [newDiscountCode],
      },
    })
      .then(async () => {
        await onSuccess();
        setDiscountLoading(false);
      })
      .catch(() => {
        setDiscountLoading(false);
      });
  };

  const removeDiscount = () => {
    setDiscountLoading(true);

    return submitDiscountApi({
      variables: {
        cartToken: token,
        code,
        couponCodes: [],
      },
    })
      .then(async () => {
        await onSuccess();
        setDiscountLoading(false);
        setDiscountCode(null);
      })
      .catch(() => {
        setDiscountLoading(false);
      });
  };

  const discountError =
    discountApiError?.message ??
    discountSubmitData?.applyDiscountCode.errors[0]?.message;

  return {
    discountCode,
    applyDiscount,
    removeDiscount,
    submitDiscountApi,
    discountSubmitData,
    discountLoading,
    discountError,
  };
};
