import { useNavigate } from "react-router-dom";
import {
  PaymentInput,
  useSubmitShopNowV2CheckoutMutation,
} from "../../../gql/__generated__/schema";
import { CONFIRMATION_ROUTE } from "../../components/AppRouter";
import { SubmitPaymentCheckoutFn } from "./usePayment";

interface SubmitProps {
  paymentRequired: boolean;
  makePayment: SubmitPaymentCheckoutFn;
  paymentClientSecret: string;
  code: string;
  cartToken: string;
  updateConsumerPreferences: () => Promise<void>;
}

const useSubmit = ({
  paymentRequired,
  makePayment,
  code,
  cartToken,
  updateConsumerPreferences,
}: SubmitProps) => {
  const navigate = useNavigate();
  const [
    submitCheckoutAPI,
    { data: submitData, loading: loadingSubmitCheckout },
  ] = useSubmitShopNowV2CheckoutMutation();

  const submitCheckoutComplete = async (payment?: PaymentInput) =>
    submitCheckoutAPI({
      variables: {
        checkout: {
          code,
          cartToken,
          payment,
        },
      },
    });

  const onSubmitHandler = async () => {
    try {
      if (paymentRequired) {
        const result = await makePayment();
        await submitCheckoutComplete({
          gateway: "stripe",
          paymentIntentId: result.paymentId,
        });
      } else {
        await submitCheckoutComplete();
      }

      await updateConsumerPreferences();
      navigate(CONFIRMATION_ROUTE);
    } catch (error) {
      console.error(error);
    }
  };
  return {
    onSubmitHandler,
    submitData,
    loadingSubmitCheckout,
  };
};

export default useSubmit;
