import {
  CurrencyAmount,
  OrderItem,
} from "@narvar/nth-kit-returns-headless/dist/src/graphql/generated";
import {
  ExtendedFee,
  ExtendedReturnItemInput,
  RefundMethodOption,
  RefundSummary,
} from "@narvar/nth-kit-returns-headless";
import { config } from "../../config";
import {
  Amount,
  CartSummary,
  Discount,
  FeeBreakdown,
  LineItem,
  Refund,
  ReturnLineItem,
} from "../../../gql/__generated__/schema";

export function transformNthCurrency(currency: Amount): CurrencyAmount {
  return {
    currency: currency.currencyCode,
    value: currency.amount,
  };
}

export function transformRefund(
  refund: Refund,
  cartSummary: CartSummary,
  shopNowCartSummary: CartSummary,
): RefundSummary {
  const refundMethod: RefundMethodOption = {
    id: refund.refundMethod,
    type: "ORIGINAL_PAYMENT",
    name: refund.refundMethodDisplay,
    description: refund.refundMethodDescription,
    refund: transformNthCurrency(refund.value),
  };

  // Adding -ve sign to reflect the refund amount which is expected in nth
  const total = transformNthCurrency({
    ...cartSummary.total,
    amount: -1 * cartSummary.total.amount,
  });
  const subtotal = transformNthCurrency(shopNowCartSummary.subtotal);

  return {
    displayAllFees: true,
    hideSubtotal: true,
    total,
    subtotal,
    refundMethod,
  };
}

export function transformDiscounts(discounts: Discount[]): ExtendedFee[] {
  return discounts.map(discount => ({
    //TODO: Add translations
    title:
      discount.targetType === "SHIPPING_LINE"
        ? "Shipping Discount"
        : "Order Discount",
    type: "SERVICE_FEE",
    displayAmount: discount.discountAmount.formattedValue,
    amount: {
      value: discount.discountAmount.amount,
      currency: discount.discountAmount.currencyCode,
    },
  }));
}

export function transformCredit(credit: Amount): ExtendedFee {
  return {
    title: "Credits Applied",
    type: "LABEL_FEE",
    displayAmount: credit.formattedValue,
    amount: {
      value: credit.amount,
      currency: credit.currencyCode,
    },
  };
}

export function transformFee(fee: FeeBreakdown): ExtendedFee {
  return {
    title: fee.description,
    type: "LABEL_FEE",
    displayAmount: fee.formattedValue,
    amount: {
      value: fee.amount,
      currency: fee.currencyCode,
    },
  };
}

export function createFee(title: string, fee: Amount): ExtendedFee {
  return {
    title,
    type: "LABEL_FEE",
    displayAmount: fee.formattedValue,
    amount: {
      value: fee.amount,
      currency: fee.currencyCode,
    },
  };
}

export function transformFees(fees: FeeBreakdown[]): ExtendedFee[] {
  return fees
    .filter(
      i =>
        !config.shouldHideNarvarSeamlessExchangeValue ||
        (i.description != "NARVAR_SEAMLESS_EXCHANGE_VALUE" &&
          i.description != "NARVAR_SEAMLESS_EXCHANGE_VALUE_TAX"),
    )
    .map(fee => transformFee(fee));
}

export function transformOrderItem(item: ReturnLineItem): OrderItem {
  const availableReasons = [
    {
      id: item.returnReason.id,
      name: item.returnReason.name,
    },
  ];

  return {
    imageUrl: item.imageUrl,
    itemId: item.id,
    name: item.name ?? "",
    availableReturnActions: [],
    price: {
      value: item.price.amount,
      currency: item.price.currencyCode,
    },
    orderedQuantity: item.quantity,
    quantity: item.quantity,
    remainingQuantity: item.quantity,
    eligibilities: {},
    availableReasons,
  };
}

export function transformShopNowItem(item: LineItem): OrderItem {
  return {
    imageUrl: item.imageUrl,
    itemId: item.id,
    name: item.name ?? "",
    availableReturnActions: [],
    price: {
      value: item.cost.amountPerQuantity.amount,
      currency: item.cost.amountPerQuantity.currencyCode,
    },
    discountedPrice: {
      value: item.cost.discountedAmountPerQuantity.amount,
      currency: item.cost.discountedAmountPerQuantity.currencyCode,
    },
    orderedQuantity: item.quantity,
    quantity: item.quantity,
    remainingQuantity: item.quantity,
    eligibilities: {},
  };
}

export function transformOrderItems(items: ReturnLineItem[]): OrderItem[] {
  return items.map(transformOrderItem);
}

export function transformShopNowItems(items: LineItem[]): OrderItem[] {
  return items.map(transformShopNowItem);
}

export function transformSelectedItem(
  item: ReturnLineItem,
): ExtendedReturnItemInput {
  return {
    ...transformOrderItem(item),
    reason: {
      id: item.returnReason.id,
    },
  };
}

export function transformSelectedItems(
  items: ReturnLineItem[],
): ExtendedReturnItemInput[] {
  return items.map(transformSelectedItem);
}
