import React from "react";
import { Route, Routes } from "react-router-dom";
import Container from "@material-ui/core/Container";
import clsx from "clsx";

import {
  adminStyleConfig,
  getBranding,
  isAdvanced,
} from "../../shared/modules/config";
import { makeRootStyles } from "../theme/styles";
import { config } from "../config";
import ReturnOrder from "./ReturnOrder";
import Checkout from "./Checkout";
import CheckoutConfirmation from "../checkoutConfirmation/CheckoutConfirmation";

export const BASE_URL = "/app_proxy";

export const CHECKOUT_ROUTE = BASE_URL + "/checkout_app";
export const CONFIRMATION_ROUTE = CHECKOUT_ROUTE + "/confirmation";

const useStyles = makeRootStyles(
  theme => ({
    root: {
      paddingTop: getBranding("root_margin_top"),
      paddingBottom: getBranding("root_margin_bottom"),
    },
    container: {
      overflow: "hidden",
    },
  }),
  { name: "N0App" },
);

function AppRouter() {
  const classes = useStyles();

  return (
    <div className={clsx(config.baseClassName, classes.root)}>
      <Container fixed className={classes.container} maxWidth="md">
        {isAdvanced && <style>{adminStyleConfig}</style>}
        <Routes>
          <Route path={CHECKOUT_ROUTE} element={<Checkout />} />
          <Route path={CONFIRMATION_ROUTE} element={<CheckoutConfirmation />} />
          <Route path="*" element={<ReturnOrder />} />
        </Routes>
      </Container>
    </div>
  );
}

export default AppRouter;
