import { isEmpty } from "lodash";
import { useMemo } from "react";
import { config } from "../../config";

const useNthFees = (refund, isCharge) =>
  useMemo(() => {
    if (!isEmpty(refund)) {
      const ret = [];

      ret.push({
        title: refund.taxesIncluded
          ? config.translations.return_review_taxes_included
          : config.translations.return_review_total_tax,
        type: "TAX",
        displayAmount: refund.totalTaxDisplayAmt,
      });

      if (refund.priorRefundsDisplayAmt) {
        ret.push({
          title: config.translations.return_review_prior_refunds,
          type: "OTHERS",
          displayAmount: refund.priorRefundsDisplayAmt,
        });
      }

      if (refund.originalShippingRefundedDisplayAmt) {
        ret.push({
          title: config.translations.return_review_original_purchase_shipping,
          type: "OTHERS",
          displayAmount: refund.originalShippingRefundedDisplayAmt,
        });
      }

      (refund.feeBreakdown ?? [])
        .filter(
          i =>
            !config.shouldHideNarvarSeamlessExchangeValue ||
            (i.description != "NARVAR_SEAMLESS_EXCHANGE_VALUE" &&
              i.description != "NARVAR_SEAMLESS_EXCHANGE_VALUE_TAX"),
        )
        .forEach(fee => {
          ret.push({
            title: fee.description,
            type: "LABEL_FEE",
            displayAmount: fee.formattedFee,
          });
        });

      if (refund.exchangeShippingFeeDisplayName) {
        const sign =
          isCharge &&
          parseFloat(refund.exchangeShippingFeeDisplayPrice.substring(1)) !== 0
            ? "-"
            : "";
        ret.push({
          title: refund.exchangeShippingFeeDisplayName,
          type: "OTHERS",
          displayAmount: sign + refund.exchangeShippingFeeDisplayPrice,
        });
      }

      return ret;
    }
  }, [refund, isCharge]);

export default useNthFees;
