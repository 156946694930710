import React from "react";
import ErrorSnackbar from "../components/ErrorSnackbar";
import MailingAddressForm from "../steps/ConfirmMailingAddress/MailingAddressForm";
import errorNotifier from "../../shared/modules/error-notifier";
import { getTranslation } from "../../shared/modules/config";
import { Address, AddressEvent } from "./hooks/useAddress";

interface AddressProps {
  onChange: (event: AddressEvent) => void;
  errorMessage?: string;
  address: Address;
}

const BillingAddress = ({ onChange, address, errorMessage }: AddressProps) => {
  if (!errorMessage) return null;
  return (
    <>
      <ErrorSnackbar message={errorMessage} />
      <MailingAddressForm
        address={address}
        onChange={onChange}
        onSubmit={({ fromAddress }) => {
          errorNotifier.warn(
            "Submit button of <MailingAddressFrom/> should be hidden, but we received onSubmit event",
            { fromAddress },
          );
        }}
        disable={null}
        submitLabel={getTranslation("bad_mailing_address_submit")}
        validateZip
        exclude={["phone", "submit"]}
      />
    </>
  );
};

export default BillingAddress;
