import { useEffect, useState } from "react";

import { z } from "zod";
const optionalNullableString = () => z.string().optional().nullable();
export const confirmationStorageSchema = z.object({
  type: z.string(),
  refundMethod: z.string().optional(),
  noInstructions: z.boolean(),
  email: z.string().email().optional(),
  guestReturn: z.boolean(),
  shipment: z.object({
    items: z.array(
      z.object({
        name: z.string(),
        price: z.object({
          value: z.number(),
          currency: z.string(),
        }),
        quantity: z.number(),
        reason: z.object({}).passthrough(),
        customerComments: optionalNullableString(),
        imageUrl: z.string().url().optional().nullable(),
        sku: z.string().optional().nullable(),
      }),
    ),
    returnLabels: z.array(
      z.object({
        labelType: z.string(),
        mimeType: z.string(),
        labelUrl: z.string().url().nullable().optional(),
      }),
    ),
  }),
  providerMoniker: z.string(),
  location: z
    .object({
      id: z.string(),
      storeName: optionalNullableString(),
      latitude: z.number().optional(),
      longitude: z.number().optional(),
      storeId: optionalNullableString(),
      displayName: optionalNullableString(),
      locationType: optionalNullableString(),
      logoUrls: z.array(z.string()).optional().nullable(),
      openingHours: z.array(z.unknown()).optional().nullable(),
      phoneNumber: optionalNullableString(),
      address: z
        .object({
          address1: optionalNullableString(),
          address2: optionalNullableString(),
          city: optionalNullableString(),
          phone: optionalNullableString(),
          state: optionalNullableString(),
          zip: optionalNullableString(),
          regionCode: optionalNullableString(),
          postalCode: optionalNullableString(),
          countryCode: optionalNullableString(),
        })
        .optional(),
    })
    .optional(),
  savePreferences: z.boolean(),
  orderId: z.string(),
  selectedRefundMethodType: z.string().optional(),
});

export type ConfirmationStorage = z.infer<typeof confirmationStorageSchema>;

const useCheckoutLocalStorageData = () => {
  const [confirmationData, setConfirmationData] =
    useState<ConfirmationStorage>();

  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    try {
      const state = JSON.parse(
        window.localStorage.getItem("nv_shop_now_v2_confirmation") ?? "{}",
      );
      //TODO: Remove local storage after parsing
      const data = confirmationStorageSchema.parse(state);
      setConfirmationData(data);
    } catch (e) {
      console.error(e);
      setError("There was an error processing your request.");
    }
  }, []);

  return {
    confirmationData,
    errorParsingLocalStorage: error,
  };
};

export default useCheckoutLocalStorageData;
