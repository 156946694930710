import React, { useEffect } from "react";
import { Theme } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import { Confirmation as NthConfirmation } from "@narvar/nth-kit-returns-headless";
import { makeRootStyles } from "../theme/styles";
import { useConsumerPreferences } from "../data/consumerPreferences";
import useCheckoutLocalStorageData from "../hooks/useCheckoutLocalStorageData";
import { cancelShopNowCheckout } from "../../shared/modules/frame";

const useStyles = makeRootStyles(
  (theme: Theme) => ({
    root: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(12),
      display: "flex",
      alignItems: "center",
    },
    checkIcon: {
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      fontSize: "5rem",
    },
    title: {},
    subtitle: {},
    supplementaryText: {},
    gutterBottom: {
      marginBottom: theme.spacing(4),
      [theme.breakpoints.up("md")]: {
        marginBottom: theme.spacing(2),
      },
    },
    labelButton: {
      marginBottom: theme.spacing(2),
    },
    continueShoppingButton: {},
  }),
  { name: "N0Confirmation" },
);

const CheckoutConfirmation = () => {
  const { confirmationData, errorParsingLocalStorage } =
    useCheckoutLocalStorageData();

  if (errorParsingLocalStorage) {
    return (
      <div>Sorry, there was an error while loading. Please try again!</div>
    );
  }

  const {
    guestReturn,
    email,
    savePreferences,
    selectedRefundMethodType,
    orderId,
    type,
    refundMethod,
    noInstructions,
    shipment,
    providerMoniker,
    location,
  } = confirmationData || {};

  const { preferencesStatus, updateConsumerPreferences } =
    useConsumerPreferences(guestReturn ? "" : email);

  useEffect(() => {
    if (savePreferences && selectedRefundMethodType) {
      updateConsumerPreferences(orderId, email, {
        returnMethods: [selectedRefundMethodType],
        carriers: [],
        locations: [],
      });
    }
  }, [savePreferences]);

  useEffect(() => {
    // This is added to remove shop now checkout banner on confirmation page
    cancelShopNowCheckout();
  }, []);

  const classes: any = useStyles();
  return (
    <Fade in={true} timeout={800}>
      <div className={classes.root}>
        <Grid justifyContent="center" spacing={2} container>
          <Grid xs={12} item>
            {errorParsingLocalStorage ? (
              <div>
                Sorry, there was an error while loading. Please try again!
              </div>
            ) : null}
            {confirmationData ? (
              <NthConfirmation
                type={type}
                refundMethod={refundMethod}
                noInstructions={noInstructions}
                email={email}
                // TODO: Fix type errors
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                shipment={shipment}
                providerMoniker={providerMoniker}
                // TODO: Fix type errors
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                location={location}
                preferencesStatus={
                  savePreferences ||
                  preferencesStatus?.profileStatus === "active"
                    ? preferencesStatus
                    : undefined
                }
              />
            ) : null}
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
};

export default CheckoutConfirmation;
