import React, { useEffect, useState } from "react";
import Fade from "@material-ui/core/Fade";
import { ApolloProvider } from "@apollo/client";
import { ThemeProvider, StylesProvider } from "@material-ui/styles";
import ResizeObserver from "resize-observer-polyfill";
import { GrowthBookProvider } from "@growthbook/growthbook-react";
import { isNil } from "lodash/fp";

import { config } from "../config";
import ReturnStepsProvider, {
  buildInitialState,
} from "../contexts/returnSteps";

import {
  createClientWithoutToken,
  createCache,
  getGraphQLPath,
} from "../../shared/modules/apollo";
import { setWindowFrameBoxSize } from "../../shared/modules/frame";
import useTheme from "../theme/mui";
import CssBaseline from "./CssBaseline";
import { withBackdropContainerProvider } from "../contexts/backdropContainer";
import ErrorBoundary from "./ErrorBoundary";
import errorNotifier from "../../shared/modules/error-notifier";
import { createClassNameGenerator } from "../../shared/theme/styles";
import { getConfig } from "../../shared/modules/config";

import apolloCacheConfig from "../data/apolloCacheConfig";
import { NthContextProvider } from "../contexts/nthContext";
import FrameDimensionsProvider from "../contexts/frameDimensions";
import { initGrowthBook } from "../../shared/modules/growthBook";
import noflake from "../../shared/modules/noflake";
import titan from "../../shared/modules/titan";
import StripeProvider from "../contexts/StripeProvider";
import {
  abTestingTrackResult,
  countingEvent,
  EXPERIMENT_VIEW_EVENT,
  PAGE_VIEW_EVENT,
} from "../data/abTesting";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./AppRouter";

errorNotifier.init();
errorNotifier.setUser({ id: config.shid });

const hasAnyAbTestingFeatures = false;
const growthBook = hasAnyAbTestingFeatures
  ? initGrowthBook({
      enableDevMode: config.environment === "development",
      attributes: {
        shopDomain: config.shopDomain,
        retailer: config.shopDomain,
      },

      // Experimentation (A/B Testing), only execute once when an experiment
      // start. It won't execute when it is an enforcement rule.
      trackingCallback: (experiment, result) => {
        abTestingTrackResult(
          growthBook,
          countingEvent(EXPERIMENT_VIEW_EVENT),
          experiment.key,
        );
        errorNotifier.setFeatures({ [experiment.key]: result.value });
      },
      // Feature Usage Callback, may execute multiple times
      onFeatureUsage: (featureKey, result) => {
        if (!isNil(result.value)) {
          errorNotifier.setFeatures({ [featureKey]: result.value });
        }
      },
    })
  : null;

// Setup global config of noflake
noflake.endpoint =
  config.environment === "production"
    ? "https://titanprod.narvar.com/v2/noflake"
    : config.environment === "staging"
      ? "https://titanlite-235686439465.us-central1.run.app/v2/noflake"
      : "";
noflake.defaults = {
  request_page: "shopz/consumer_app",
  shop_domain: config.shopDomain,
};

// Setup global config of titan
titan.endpoint =
  config.environment === "production"
    ? "https://titanprod.narvar.com/"
    : config.environment === "staging"
      ? "https://titanlite.narvar.com"
      : "";
titan.retailerMoniker = config.shopDomain;

const observer = new ResizeObserver(entries => {
  const body = entries[0];
  console.debug("setWindowFrameBoxSize", body.contentRect.height);
  setWindowFrameBoxSize(body.contentRect.height);
});
observer.observe(document.querySelector("body"));

const classNameGenerator = createClassNameGenerator();

const App = () => {
  const theme = useTheme(getConfig("branding"));
  const [inited, setInited] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      // app fade in
      setInited(true);

      // update rollbar tracking info (shopUrl is an async iframe message from parent document)
      if (config.shopUrl) {
        errorNotifier.setUser({
          id: config.shid,
          shopUrl: config.shopUrl,
        });
      }
    }, 500);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    growthBook?.loadFeatures?.({ autoRefresh: false, timeout: 2000 });
    abTestingTrackResult(growthBook, countingEvent(PAGE_VIEW_EVENT));
  }, []);

  const client = createClientWithoutToken(
    createCache(apolloCacheConfig),
    getGraphQLPath(),
  );

  return (
    <StylesProvider generateClassName={classNameGenerator}>
      <ErrorBoundary>
        <GrowthBookProvider growthbook={growthBook}>
          <ThemeProvider theme={theme}>
            <FrameDimensionsProvider>
              <CssBaseline />
              <ApolloProvider client={client}>
                <StripeProvider>
                  <ReturnStepsProvider initialState={buildInitialState()}>
                    <NthContextProvider>
                      <Fade in={inited}>
                        <BrowserRouter>
                          <AppRouter />
                        </BrowserRouter>
                      </Fade>
                    </NthContextProvider>
                  </ReturnStepsProvider>
                </StripeProvider>
              </ApolloProvider>
            </FrameDimensionsProvider>
          </ThemeProvider>
        </GrowthBookProvider>
      </ErrorBoundary>
    </StylesProvider>
  );
};

export default withBackdropContainerProvider(App);
